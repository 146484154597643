<template>
	<div
		class="calculate pa-0"
	>
		<div
			class="pa-8 bg-color"
		>
			<div
				class="mt-1 text-center font-weight-bold text-h6"
			>
				<span class="d-inline-block under-line">{{ user.salesManager }}님</span>
			</div>
			
			<div
				class="mt-5 text-center font-weight-bold text-h4"
			>
				{{ summary.settlementTotalAmount | makeComma }}원
			</div>
		</div>
		
		<PickerMonth
			class="picker"
			@click="pickerMonth"
		>
		</PickerMonth>
		
		<div
			class="mt-2 pa-2 list-colculate"
		>
		
			<template
				v-for="(item, index) in items"
			>
			<v-card
				v-if="item.settlementDate <= today"
				:key="index"
				class="mb-3 pa-2 text-body-2"
				:to="'/Colculate/Item/' + item.settlementDate"
			>
			
				<v-row>
					<v-col
						cols="3"
						class="text-center line-right"
					>
						<br>
						<div class="text-color text-h4">{{ item.settlementDate | typeDay}} </div>
						<div class="text-gray">{{ item.settlementDate | typeWeek}}</div>
					</v-col>
					<v-col
						cols="9"
						class="text-gray"
					>
						<div>신용카드 결제 <span class="float-right text-black">{{ item.cardPaymentCount}} 건</span></div>
						<div>결제금액 <span class="float-right text-black">{{ item.paymentAmount | makeComma}} 원</span></div>
						<div>정산 수수료 <span class="float-right text-black">{{ item.feeAmount | makeComma}} 원</span></div>
						<div class="text-black"><span class="font-weight-bold">정산금액</span> <span class="float-right"><span class="text-color">{{ item.settlementAmount | makeComma}}</span> 원</span></div>
					</v-col>
				</v-row>
			</v-card>
			</template>
		</div>
		
		
		<div class="pa-0 mt-3" style="margin-bottom: 55px;">
			<div
				class="pa-2 text-center bg caption"
			>
				정산 주기: {{ user.settlementCycle }} / 정산 수수료: {{ user.commissionRate }}%
			</div>
		</div>
	</div>
</template>

<script>
	import PickerMonth from '@/components/PickerMonth'
	
	export default{
		name: 'PaymentRegist'
		,created: function(){
			this.$emit('setProgram', this.program, this.options)
		}
		,components: { PickerMonth }
		,props: ['user', 'callBack']
		,data: function(){
			return {
				program: {
					title: '정산 현황'
					,pre: {
						to: '/Home'
					}
				}
				,date: {
					year: ''
					,month: ''
				}
				,inquiryDate: ''
				,today: ''
				,summary: {
				}
				,items: [
				]
				,sample: [
					{
						id: 1
						,date: 30
						,week: '수요일'
						,card_count: 5
						,payment_price: 1500000
						,payment_fee: 15000
						,total_price: 1485000
					}
					,{
						id: 1
						,date: 29
						,week: '화요일'
						,card_count: 12
						,payment_price: 1500000
						,payment_fee: 15000
						,total_price: 1485000
					}
					,{
						id: 1
						,date: 28
						,week: '월요일'
						,card_count: 5
						,payment_price: 1500000
						,payment_fee: 15000
						,total_price: 1485000
					}
					,{
						id: 1
						,date: 27
						,week: '일요일'
						,card_count: 5
						,payment_price: 1500000
						,payment_fee: 15000
						,total_price: 1485000
					}
					,{
						id: 1
						,date: 26
						,week: '토요일'
						,card_count: 5
						,payment_price: 1500000
						,payment_fee: 15000
						,total_price: 1485000
					}
				]
			}
		}
		,filters: {
			typeDay: function(val){
				return val.slice("-2")
			}
			,typeWeek: function(val){
				let weeks = ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일']
				let date = new Date(val.substring(0, 4) + '-' + val.substring(4, 6) + '-' + val.substring(6, 8))
				return weeks[date.getDay()]
			}
		}
		,methods: {
			getData: function(inquiryDate){
				this.$emit('axios', {
					request_type: 'get'
					,request_url: '/settlement/summary'
					,request_params: {
						inquiryDate: inquiryDate
					}
					,authorize: true
					,callBack: 'setItems'
				})
			}
			,setItems: function(call){
				this.summary = call.items.content
				this.items = call.items.content.daySummary
			}
			,pickerMonth: function(call){
				console.log('calculate pickermonth')
				this.today = call.today
				this.inquiryDate = call.fullDate
			}
		}
		,watch: {
			callBack: {
				deep: true
				,handler: function(call){
					if(call.name == 'setItems'){
						this.setItems(call)
					}else if(call.name == 'getSummaryResult'){
						this.getSummaryResult(call)
					}
				}
			}
			,inquiryDate: {
				handler: function(call){
					console.log('watch calculate inquiryDate')
					console.log(call)
					this.getData(call)
				}
			}
		}
	}
</script>

<style>
	.calculate {}
	.calculate .picker {padding: 5px 5px 10px;border-top: 1px solid #ddd; border-bottom: 1px solid #ddd;}
	.calculate .bg-color {background-color: #f3fdfc;}
	.calculate .under-line {border-bottom: 3px solid #00BFA5;}
	.calculate .caption {border-top: 2px solid #555; border-bottom: 1px solid #ddd; border-radius: 3px;}
	.calculate th {border-right: 1px solid #ddd;}
</style>